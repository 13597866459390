import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';
import moment from 'moment';
import {useCustomSnackbar} from './useCustomSnackbar';

export function useExcelOrCsvGenerator() {
  const {showError} = useCustomSnackbar();

  function generatePartExcelOrCsv(parts, p, isExcel) {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(p.tc('vehicles'));
    worksheet.columns = [
      {header: p.tc('id'), key: 'id'},
      {header: p.tc('title'), key: 'title'},
      {header: p.tc('marketplace'), key: 'marketplace'},
      {header: p.tc('rack'), key: 'rack'},
      {header: p.tc('bin'), key: 'bin'},
      {header: p.tc('vehicle id'), key: 'vehicleId'},
      {header: p.tc('category'), key: 'category'},
      {header: p.tc('mpn'), key: 'mpn'},
      {header: p.tc('reference'), key: 'reference'},
      {header: p.tc('dimensions (cm)'), key: 'dimensions'},
      {header: p.tc('weight (kg)'), key: 'weight'},
      {header: p.tc('color'), key: 'color'},
      {header: p.tc('condition description'), key: 'conditionDescription'},
      {header: p.tc('price'), key: 'price'},
      {header: p.tc('mounting side'), key: 'mountingSide'},
      {header: p.tc('brand'), key: 'brand'},
      {header: p.tc('status'), key: 'status'},
      {header: p.tc('quantity'), key: 'quantity'},
      {header: p.tc('internal notes'), key: 'internalNotes'},
      {header: p.tc('quality'), key: 'quality'},
    ];
    for (const part of parts) {
      worksheet.addRow({
        id: part.id,
        title: part.title || '',
        marketplace:
          (part.marketplaces &&
            part.marketplaces.map(marketplace => marketplace.name).join(',')) ||
          '',
        rack: (part.storingPlace && part.storingPlace.name) || '',
        bin:
          (part.storingPlace &&
            part.storingPlace.box &&
            part.storingPlace.box.name) ||
          '',
        vehicleId: part.vehicleId || '',
        category: part.exactPartCategoryName || '',
        mpn: part.manufacturerPartNumber || '',
        reference:
          (part.references &&
            part.references
              .map(reference => reference.description)
              .join(',')) ||
          '',
        dimensions: part.dimensions || '',
        weight: part.weight || '',
        color: (part.color && part.color.name) || '',
        conditionDescription:
          (part.conditionDescriptions &&
            part.conditionDescriptions
              .map(description => description.description)
              .join(',')) ||
          '',
        price: (part.product && part.product.price) || '',
        mountingSide:
          (part.mountingSides &&
            part.mountingSides
              .map(mountingSide => mountingSide.name)
              .join(',')) ||
          '',
        brand: part.brandName || '',
        status:
          (part.product && part.product.status && part.product.status.name) ||
          '',
        quantity: part.quantity || '',
        internalNotes:
          (part.internalNote && part.internalNote.replace(/\n/g, ' ')) || '',
        quality:
          (part.conditionDescriptions &&
            part.conditionDescriptions.sort((a, b) => {
              if (a.conditionLetter < b.conditionLetter) {
                return 1;
              } else if (a.conditionLetter > b.conditionLetter) {
                return -1;
              }
              return 0;
            })[0]?.conditionLetter) ||
          '',
      });
    }
    if (isExcel) {
      workbook.xlsx
        .writeBuffer()
        .then(buffer =>
          FileSaver.saveAs(
            new Blob([buffer]),
            `${moment().format('YYYY-MM-DD_HH-mm-ss')}_${p.t('parts')}.xlsx`
          )
        )
        .catch(error => {
          showError(error);
        });
    } else {
      workbook.csv
        .writeBuffer()
        .then(buffer =>
          FileSaver.saveAs(
            new Blob([buffer]),
            `${moment().format('YYYY-MM-DD_HH-mm-ss')}_${p.t('parts')}.csv`
          )
        )
        .catch(error => {
          showError(error);
        });
    }
  }

  function generateVehicleExcelOrCsv(
    vehicles,
    p,
    isExcel,
    isVehicleAsCollection = false
  ) {
    const workbook = new ExcelJS.Workbook();
    const name = `${isVehicleAsCollection ? 'collections' : 'vehicles'}`;
    const worksheet = workbook.addWorksheet(p.tc(name));
    worksheet.columns = [
      {header: p.tc('id'), key: 'id'},
      {header: p.tc('vehicle type'), key: 'vehicleType'},
      {header: p.tc('registration number'), key: 'registrationNumber'},
      {header: p.tc('vin code'), key: 'vinCode'},
      {header: p.tc('vehicle location'), key: 'vehicleLocation'},
      {header: p.tc('condition description'), key: 'conditionDescription'},
      {header: p.tc('note'), key: 'note'},
      {header: p.tc('manufacture year'), key: 'year'},
      {header: p.tc('make'), key: 'make'},
      {header: p.tc('model'), key: 'model'},
      {header: p.tc('body type'), key: 'bodyType'},
      {header: p.tc('fuel type'), key: 'fuelType'},
      {header: p.tc('engine capacity'), key: 'engineCapacity'},
      {header: p.tc('engine power'), key: 'enginePower'},
      {header: p.tc('gearbox type'), key: 'gearboxType'},
      {header: p.tc('driven axle'), key: 'drivenAxle'},
      {header: p.tc('steering wheel position'), key: 'steeringWheelPosition'},
      {header: p.tc('engine code'), key: 'engineCode'},
      {header: p.tc('gearbox code'), key: 'gearboxCode'},
      {header: p.tc('color'), key: 'color'},
      {header: p.tc('color code'), key: 'colorCode'},
      {header: p.tc('number of doors'), key: 'numberOfDoors'},
      {header: p.tc('number of gears'), key: 'numberOfGears'},
      {header: p.tc('mileage'), key: 'mileage'},
      {header: p.tc('trim code'), key: 'trimCode'},
      {header: p.tc('seating capacity'), key: 'seatingCapacity'},
      {header: p.tc('emission standard'), key: 'emissionStandard'},
      {header: p.tc('wheel type'), key: 'wheelSize'},
      {header: p.tc('headlight type'), key: 'headlightType'},
      {header: p.tc('startStop'), key: 'startStop'},
      {header: p.tc('price'), key: 'price'},
    ];
    for (const vehicle of vehicles) {
      worksheet.addRow({
        id: vehicle.id,
        vehicleType:
          (vehicle.vehicleCategory && vehicle.vehicleCategory.name) || '',
        registrationNumber: vehicle.registrationNumber || '',
        vinCode: vehicle.vinCode || '',
        vehicleLocation: vehicle.storingPlace || '',
        conditionDescription:
          (vehicle.conditionDescriptions &&
            vehicle.conditionDescriptions
              .map(condition => condition.description)
              .join(',')) ||
          '',
        note:
          (vehicle.product &&
            vehicle.product.note &&
            vehicle.product.note.replace(/\n/g, ' ')) ||
          '',
        year: vehicle.manufactureYear || '',
        make: (vehicle.kType && vehicle.kType.make) || '',
        model: (vehicle.kType && vehicle.kType.model) || '',
        bodyType: (vehicle.kType && vehicle.kType.bodyType) || '',
        fuelType: (vehicle.kType && vehicle.kType.fuelType) || '',
        engineCapacity: vehicle.engineCapacity || '',
        enginePower:
          (vehicle.kType &&
            vehicle.kType.enginePowerKw +
              'KW (' +
              vehicle.kType.enginePowerHp +
              ' HP)') ||
          '',
        gearboxType: (vehicle.gearbox && vehicle.gearbox.name) || '',
        drivenAxle: (vehicle.kType && vehicle.kType.drivenAxle) || '',
        steeringWheelPosition: vehicle.steeringWheelPosition || '',
        engineCode: vehicle.engineCode || '',
        gearboxCode: vehicle.gearboxCode || '',
        color: (vehicle.color && vehicle.color.name) || '',
        colorCode: vehicle.colorCode || '',
        numberOfDoors: vehicle.numberOfDoors || '',
        numberOfGears: vehicle.numberOfGears || '',
        mileage: vehicle.mileage || '',
        trimCode: vehicle.trimCode || '',
        seatingCapacity: vehicle.seatingCapacity || '',
        emissionStandard:
          (vehicle.emissionStandard && vehicle.emissionStandard.name) || '',
        wheelSize: vehicle.wheelSize || '',
        headlightType: vehicle.headlightType || '',
        startStop: vehicle.startStop || '',
        price: (vehicle.product && vehicle.product.price) || '',
      });
    }
    if (isVehicleAsCollection) {
      worksheet.spliceColumns(2, 1);
    }
    if (isExcel) {
      workbook.xlsx
        .writeBuffer()
        .then(buffer =>
          FileSaver.saveAs(
            new Blob([buffer]),
            `${moment().format('YYYY-MM-DD_HH-mm-ss')}_${p.t(name)}.xlsx`
          )
        )
        .catch(error => {
          showError(error);
        });
    } else {
      workbook.csv
        .writeBuffer()
        .then(buffer =>
          FileSaver.saveAs(
            new Blob([buffer]),
            `${moment().format('YYYY-MM-DD_HH-mm-ss')}_${p.t(name)}.csv`
          )
        )
        .catch(error => {
          showError(error);
        });
    }
  }

  function generateOrderExcelOrCsv(orders, p, isExcel) {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(p.tc('orders'));
    worksheet.columns = [
      {header: p.tc('shipment number'), key: 'shipmentNumber'},
      {header: p.tc('item id'), key: 'itemId'},
      {header: p.tc('invoice number'), key: 'invoiceNumber'},
      {header: p.tu('sku'), key: 'sku'},
      {header: p.tc('item title'), key: 'itemTitle'},
      {header: p.tc('quantity'), key: 'quantity'},
      {header: p.tc('name'), key: 'name'},
      {header: p.tc('surname'), key: 'surname'},
      {header: p.tc('address') + '1', key: 'address1'},
      {header: p.tc('address') + '2', key: 'address2'},
      {header: p.tc('city'), key: 'city'},
      {header: p.tc('region'), key: 'region'},
      {header: p.tc('postcode'), key: 'postcode'},
      {header: p.tc('country'), key: 'country'},
      {header: p.tc('email'), key: 'email'},
      {header: p.tc('phone'), key: 'phone'},
      {header: p.tc('marketplace order ID'), key: 'marketplaceOrderId'},
      {header: p.tc('sold on'), key: 'soldOn'},
      {header: p.tc('payment method'), key: 'paymentMethod'},
      {header: p.tc('transaction ID'), key: 'transactionId'},
      {header: p.tc('shipping price'), key: 'shippingPrice'},
      {header: p.tc('total price'), key: 'totalPrice'},
      {header: p.tc('included VAT rate'), key: 'includedVATRate'},
      {header: p.tc('sale date'), key: 'saleDate'},
      {header: p.tc('dispatch date'), key: 'dispatchDate'},
      {header: p.tc('shipping type used'), key: 'shippingType'},
      {header: p.tc('tracking number'), key: 'trackingNumber'},
      {header: p.tc('exact shipping price'), key: 'exactShippingPrice'},
    ];
    for (const order of orders) {
      worksheet.addRow({
        shipmentNumber: order.shipment?.shipmentNumber || '',
        itemId: '',
        invoiceNumber: order.invoice?.invoiceNumber || '',
        sku: '',
        itemTitle: '',
        quantity: '',
        name: order.shipment?.name || '',
        surname: order.shipment?.surname || '',
        address1: order.shipment?.addressLine1 || '',
        address2: order.shipment?.addressLine2 || '',
        city: order.orderPdfCreation?.shipmentCity?.name || '',
        region: order.shipment?.state || '',
        postcode: order.shipment?.postCode || '',
        country: order.orderPdfCreation?.shipmentCountry?.name || '',
        email: order.shipment?.email || '',
        phone: order.shipment?.phoneNumber || '',
        marketplaceOrderId: order.order?.sellingNumber || '',
        soldOn: order.orderPdfCreation?.marketplaceAccount?.name || '',
        paymentMethod: order.orderPdfCreation?.paymentMethod?.name || '',
        transactionId: order.order?.paymentTransactionId || '',
        shippingPrice: order.order?.shippingPrice || '',
        totalPrice: order.order?.totalInEuros || '',
        includedVATRate: order.order?.vatRate || '',
        saleDate: order.order?.soldDate || '',
        dispatchDate: order.shipment?.shippingDate || '',
        shippingType: order.shipment?.shippingType || '',
        trackingNumber: order.shipment?.parcelNumber || '',
        exactShippingPrice: order.shipment?.shippingPrice || '',
      });

      for (const product of order.products) {
        worksheet.addRow({
          shipmentNumber: '',
          itemId: product.itemId,
          invoiceNumber: '',
          sku:
            (product.warehouse?.shortName
              ? product.warehouse?.shortName
              : '-') +
            '|' +
            (product.part?.storingPlace?.name
              ? product.part?.storingPlace?.name
              : '-') +
            '|' +
            (product.part?.storingPlace?.box?.name
              ? product.part?.storingPlace?.box?.name
              : '-'),
          itemTitle:
            product.part?.exactPartCategoryName || product.part?.title || '',
          quantity: product.part?.quantity || '1',
          name: '',
          surname: '',
          address1: '',
          address2: '',
          city: '',
          region: '',
          postcode: '',
          country: '',
          email: '',
          phone: '',
          marketplaceOrderId: '',
          soldOn: '',
          paymentMethod: '',
          transactionId: '',
          shippingPrice: '',
          totalPrice: '',
          includedVATRate: '',
          saleDate: '',
          paidOnDate: '',
          dispatchDate: '',
          shippingType: '',
          trackingNumber: '',
          exactShippingPrice: '',
        });
      }
    }
    if (isExcel) {
      workbook.xlsx
        .writeBuffer()
        .then(buffer =>
          FileSaver.saveAs(
            new Blob([buffer]),
            `${moment().format('YYYY-MM-DD_HH-mm-ss')}_${p.t('orders')}.xlsx`
          )
        )
        .catch(error => {
          showError(error);
        });
    } else {
      workbook.csv
        .writeBuffer()
        .then(buffer =>
          FileSaver.saveAs(
            new Blob([buffer]),
            `${moment().format('YYYY-MM-DD_HH-mm-ss')}_${p.t('orders')}.csv`
          )
        )
        .catch(error => {
          showError(error);
        });
    }
  }

  return {
    generatePartExcelOrCsv,
    generateVehicleExcelOrCsv,
    generateOrderExcelOrCsv,
  };
}
