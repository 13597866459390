import React from 'react';
import {ReactComponent as AddVehicle} from '../../images/navBar/main_add_vehicle.svg';
import {ReactComponent as Vehicles} from '../../images/navBar/main_vehicles.svg';
import {ReactComponent as AddPart} from '../../images/navBar/main_add_part.svg';
import {ReactComponent as AddParts} from '../../images/navBar/main_parts.svg';
import {ReactComponent as Lists} from '../../images/navBar/main_lists.svg';
import {ReactComponent as Orders} from '../../images/navBar/main_orders.svg';
import {ReactComponent as Deleted} from '../../images/navBar/main_deleted.svg';
import {ReactComponent as Boxes} from '../../images/navBar/main_boxes.svg';
import {ReactComponent as Admin} from '../../images/navBar/main_admin.svg';
import {ReactComponent as Chat} from '../../images/navBar/main_chat.svg';
import {ReactComponent as Settings} from '../../images/navBar/main_settings.svg';
import {ReactComponent as Collections} from '../../images/navBar/main_bundle.svg';

const DrawerIconsList = [
  <AddVehicle key="0" style={{height: 30, width: 30}} />,
  <Vehicles key="1" style={{height: 30, width: 30}} />,
  <AddPart key="2" style={{height: 30, width: 30}} />,
  <AddParts key="3" style={{height: 30, width: 30}} />,
  <Lists key="4" style={{height: 30, width: 30}} />,
  <Orders key="5" style={{height: 30, width: 30}} />,
  <Deleted key="6" style={{height: 30, width: 30}} />,
  <Boxes key="7" style={{height: 30, width: 30}} />,
  <Admin key="8" style={{height: 30, width: 30}} />,
  <Chat key="9" style={{height: 30, width: 30}} />,
  <Collections key="10" style={{height: 30, width: 30}} />,
  <Settings key="11" style={{height: 30, width: 30}} />,
];

export default DrawerIconsList;
