export const EBAY_ID = 1;
export const AUTOPLIUS_ID = 3;
export const RRR_ID = 2;
export const ALLEGRO_ID = 9;
export const AUTOPLIUS_MAKES = 'Makes';
export const AUTOPLIUS_MODELS = 'Models';
export const AUTOPLIUS_LORRIE_TYPE = 'Lorrie type';
export const AUTOPLIUS_WHEEL_FORMULA = 'Wheel formula';
export const AUTOPLIUS_LISTING_CATEGORY = 'Listing category';
export const AUTOPLIUS_VEHICLE_CATEGORY = 'Vehicle category';
export const AUTOPLIUS_CATEGORY = 'Category';
export const EBAY_BRAND = 'Brand';
export const EBAY_COLOR = 'Colour';
export const EBAY_REFERENCE = 'Reference OE/OEM Number';
export const EBAY_MPN = 'Manufacturer Part Number';

export const MARKETPLACE_NAMES = {
  1: 'eBay',
  2: 'RRR',
  3: 'Autoplius',
  4: 'Autobilis',
  5: 'Autobonus',
  6: 'JustParts',
  7: 'Daviva',
  8: 'Autogidas',
  9: 'Allegro',
};
