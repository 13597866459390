import axios from 'axios';
const locale = localStorage.getItem('language');
const authRequest = axios.create({
  headers: {
    'Accept-Language': locale,
    'Content-Type': 'application/json',
  },
});
export const checkToken = () => authRequest.post('/auth/check-auth-token');
export default authRequest;
