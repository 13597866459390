import {getImageFromUrl} from '../../../api/Global';

export const svgToPngDataUrl = imageBuffer => {
  return new Promise((resolve, reject) => {
    // If it's SVG, convert to PNG
    const svgText = new TextDecoder('utf-8').decode(imageBuffer);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const DOMURL = window.URL || window.webkitURL || window;

    const img = new Image();
    const svgBlob = new Blob([svgText], {type: 'image/svg+xml'});
    const url = DOMURL.createObjectURL(svgBlob);

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      DOMURL.revokeObjectURL(url);

      resolve(canvas.toDataURL('image/png'));
    };
    img.onerror = () => reject(new Error('Error loading SVG'));
    img.src = url;
  });
};

export const getPngDataUrl = imageBuffer => {
  let binary = '';
  const bytes = new Uint8Array(imageBuffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  const base64String = window.btoa(binary);

  // Create the data URL for the PNG
  return `data:image/png;base64,${base64String}`;
};

export const convertImageDataUrlToGrayscale = dataUrl => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;

      ctx.drawImage(img, 0, 0);

      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;

      for (let i = 0; i < data.length; i += 4) {
        const avg = (data[i] + data[i + 1] + data[i + 2]) / 3;
        data[i] = avg; // red
        data[i + 1] = avg; // green
        data[i + 2] = avg; // blue
      }

      ctx.putImageData(imageData, 0, 0);
      const grayscaleDataUrl = canvas.toDataURL('image/png');
      resolve(grayscaleDataUrl);
    };
    img.onerror = () => reject(new Error('Error loading image'));
    img.src = dataUrl;
  });
};

export const getImageDataUrlFromUrl = async imageUrl => {
  const response = await getImageFromUrl(imageUrl);
  const contentType = response.headers['content-type'] || 'image/png';
  const dataUrl =
    contentType === 'image/svg+xml'
      ? await svgToPngDataUrl(response.data)
      : getPngDataUrl(response.data);
  return await convertImageDataUrlToGrayscale(dataUrl);
};
