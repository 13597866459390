import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Route} from 'react-router-dom';

const Page = ({title, changeTitle, ...props}) => {
  useEffect(() => {
    if (!changeTitle) {
      return;
    }

    if (title) {
      document.title = `${title} | JustParts`;
    } else {
      document.title = 'JustParts';
    }
  });

  return <Route {...props} />;
};

Page.propTypes = {
  title: PropTypes.string,
  changeTitle: PropTypes.bool,
};

export default Page;
