import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  Link as RouterLink,
  NavLink as RouterNavLink,
  useLocation,
} from 'react-router-dom';
import {
  Tooltip,
  ListItemIcon,
  Drawer,
  Hidden,
  List,
  Paper,
  Divider,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import DrawerIconsList from './DrawerIconsList';
import {ReactComponent as Logo} from '../../images/logo/JP_logo_on_white.svg';
import {DRAWER_WIDTH, ADMIN_ROLE_ID, USER_ROLE_ID} from '../../constants';
import BugReportIcon from '@material-ui/icons/BugReport';
import EventNoteIcon from '@material-ui/icons/EventNote';

const useStyles = makeStyles(theme => ({
  listItem: {
    paddingLeft: 8,
  },
  bug: {
    justifyContent: 'center',
  },
  logoHeight: {
    height: 28,
    width: 100,
    marginLeft: 15,
  },
  listItemIcon: {
    minWidth: '40px',
  },
  drawer: {
    [theme.breakpoints.up('lg')]: {
      flexShrink: 0,
    },
  },
  drawerPaper: {
    [theme.breakpoints.up('lg')]: {
      top: 48,
      height: 'calc(100% - 48px)',
    },
  },
  mobilePaper: {
    width: 200,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  logoPaper: {
    [theme.breakpoints.down('xs')]: {
      height: 55,
      minHeight: 55,
    },
    [theme.breakpoints.up('sm')]: {
      height: 63,
      minHeight: 63,
    },
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  activeListItem: {
    color: theme.palette.primary.main,
    borderRight: '4px solid ' + theme.palette.primary.main,
  },
  noTopPaddingInList: {
    paddingTop: '0 !important',
  },
  footer: {
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    marginTop: 'auto',
  },

  textSize: {
    fontSize: '13px !important',
  },
  noPadding: {
    padding: '0 !important',
  },
}));

const OpenBugsPage = () => {
  let blankWindow = window.open('about:blank');
  let document = blankWindow?.document;
  document.open();
  document.write(
    `<html><head><meta name="viewport" content="width=device-width, initial-scale=1.0"><script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script><script type="text/javascript" src="https://justparts.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/o2joag/b/24/a44af77267a987a660377e5c46e0fb64/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=aa37900f"></script><script type="text/javascript">window.ATL_JQ_PAGE_PROPS = {"triggerFunction": function (showCollectorDialog) {jQuery(document).ready(function () {setTimeout(function () {showCollectorDialog();}, 30);});}};</script></head><body></body></html>`
  );
  document.close();
};

export const getItems = (p, userRoleId) =>
  [
    {
      title: p.tc('add vehicle'),
      icon: DrawerIconsList[0],
      href: '/vehicles/add',
      roleId: [ADMIN_ROLE_ID, USER_ROLE_ID],
    },
    {
      title: p.tc('vehicles'),
      icon: DrawerIconsList[1],
      href: '/vehicles',
      roleId: [ADMIN_ROLE_ID, USER_ROLE_ID],
    },
    {
      title: p.tc('collections'),
      icon: DrawerIconsList[10],
      href: '/collections',
      roleId: [ADMIN_ROLE_ID, USER_ROLE_ID],
    },
    {
      title: p.tc('add part'),
      icon: DrawerIconsList[2],
      href: '/parts/manage',
      roleId: [ADMIN_ROLE_ID, USER_ROLE_ID],
    },
    {
      title: p.tc('parts'),
      icon: DrawerIconsList[3],
      href: '/parts',
      roleId: [ADMIN_ROLE_ID, USER_ROLE_ID],
    },
    {
      title: p.tc('lists'),
      icon: DrawerIconsList[4],
      href: '/lists',
      roleId: [ADMIN_ROLE_ID, USER_ROLE_ID],
    },
    {
      title: p.tc('orders'),
      icon: DrawerIconsList[5],
      href: '/orders',
      roleId: [ADMIN_ROLE_ID, USER_ROLE_ID],
    } /*
		{
			title: p.tc('deleted'),
			icon: DrawerIconsList[6],
			href: '/deleted',
			roleId: [ADMIN_ROLE_ID, USER_ROLE_ID],
		},*/,
    {
      title: p.tc('bins'),
      icon: DrawerIconsList[7],
      href: '/bins',
      roleId: [ADMIN_ROLE_ID, USER_ROLE_ID],
    },
    {
      title: p.tc('admin'),
      icon: DrawerIconsList[8],
      href: '/admin',
      roleId: [ADMIN_ROLE_ID],
    } /*
		{
			title: p.tc('chat'),
			icon: DrawerIconsList[9],
			href: '/chat',
			roleId: [ADMIN_ROLE_ID, USER_ROLE_ID],
		},*/,
    {
      title: p.tc('settings'),
      icon: DrawerIconsList[11],
      href: '/settings',
      roleId: [ADMIN_ROLE_ID, USER_ROLE_ID],
    },
  ].filter(item => item.roleId.includes(userRoleId));

const AppDrawer = ({onMobileClose, openMobile, userRoleId, p}) => {
  const classes = useStyles();
  const location = useLocation();
  const items = getItems(p, userRoleId);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  const drawer = (
    <>
      <Hidden lgUp>
        <Paper square className={classes.logoPaper}>
          <RouterLink to="/">
            <Logo className={classes.logoHeight} />
          </RouterLink>
        </Paper>
      </Hidden>
      <List classes={{padding: classes.noTopPaddingInList}}>
        {items.map((item, index) => (
          <ListItem
            button
            disableGutters
            key={index}
            component={RouterNavLink}
            exact
            to={() => {
              return item.href;
            }}
            activeClassName={classes.activeListItem}
            className={classes.listItem}
          >
            <Tooltip title={item.title}>
              <ListItemIcon className={classes.listItemIcon}>
                {item.icon}
              </ListItemIcon>
            </Tooltip>
            <Hidden lgUp>
              <ListItemText primary={item.title} />
            </Hidden>
          </ListItem>
        ))}
      </List>
      <List className={classes.footer} classes={{padding: classes.noPadding}}>
        <Divider />
        <ListItem
          button
          className={classes.bug}
          disableGutters
          component={RouterLink}
          exact="true"
          to="/patch-notes"
        >
          <EventNoteIcon />
        </ListItem>
        <ListItem
          button
          className={classes.bug}
          disableGutters
          onClick={OpenBugsPage}
        >
          <Tooltip title={p.tc('report a bug')}>
            <BugReportIcon />
          </Tooltip>
        </ListItem>
      </List>
    </>
  );

  return (
    <>
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden lgUp>
        <Drawer
          variant="temporary"
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          classes={{
            paper: classes.mobilePaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="persistent"
          open
          style={{width: DRAWER_WIDTH}}
          anchor="left"
        >
          {drawer}
        </Drawer>
      </Hidden>
    </>
  );
};

AppDrawer.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  p: PropTypes.object,
  userRoleId: PropTypes.number,
};

export default AppDrawer;
