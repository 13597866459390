import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core';
import Drawer, {getItems as getDrawerItems} from '../components/navBar/Drawer';
import TopAppBar from '../components/topAppBar/TopAppBar';
import {DRAWER_WIDTH, homeRoutes} from '../constants';
import {getP} from 'redux-polyglot';
import {connect} from 'react-redux';

const useStyles = draverWidth =>
  makeStyles(theme => ({
    root: {
      display: 'flex',
    },
    wrapper: {
      display: 'flex',
      flex: '1 1 auto',
      paddingTop: 48,
    },
    contentContainer: {
      display: 'flex',
      flex: '1 1 auto',
    },
    content: {
      flex: '1 1 auto',
      padding: '16px 12px 0px 12px',
      [theme.breakpoints.only('xs')]: {
        height: 'calc(100% + 10px)',
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: `calc(100vw - ${draverWidth}px)`,
      },
      [theme.breakpoints.down('md')]: {
        maxWidth: '100vw',
      },
    },
  }));

const MainLayout = ({children, state}) => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const p = getP(state);

  const singlePageLayout = useMemo(() => {
    const drawerItems = getDrawerItems(p, state.auth.userRoleId);
    return drawerItems.length === 0;
  }, [p, state.auth.userRoleId]);

  const classes = useStyles(singlePageLayout ? 0 : DRAWER_WIDTH)();

  const homeRoute = useMemo(() => homeRoutes[state.auth.userRoleId], [
    state.auth.userRoleId,
  ]);

  return (
    <div className={classes.root}>
      <TopAppBar
        p={p}
        homeRoute={homeRoute}
        singlePageLayout={singlePageLayout}
        onMobileNavOpen={() => {
          if (isMobileNavOpen) {
            setMobileNavOpen(false);
          } else {
            setMobileNavOpen(true);
          }
        }}
      />
      {!singlePageLayout && (
        <Drawer
          p={p}
          userRoleId={state.auth.userRoleId}
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={isMobileNavOpen}
        />
      )}
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={clsx(classes.content)} id="mainLayout">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
MainLayout.propTypes = {
  children: PropTypes.node,
  state: PropTypes.object,
};

function mapPropsToState(state) {
  return {state};
}

export default connect(mapPropsToState)(MainLayout);
