import {jsPDF} from 'jspdf';
import {fontBold, fontLight, fontRegular} from './Oswald';
import {
  fontRobotoLight,
  fontRobotoMedium,
  fontRobotoBold,
  fontRobotoRegular,
} from './Roboto';

const callAddFontBold = function () {
  this.addFileToVFS('Oswald-Bold.ttf', fontBold);
  this.addFont('Oswald-Bold.ttf', 'Oswald', 'bold');
};

const callAddFontRegular = function () {
  this.addFileToVFS('Oswald-Regular.ttf', fontRegular);
  this.addFont('Oswald-Regular.ttf', 'Oswald', 'normal');
};

const callAddFontLight = function () {
  this.addFileToVFS('Oswald-Light.ttf', fontLight);
  this.addFont('Oswald-Light.ttf', 'Oswald', 'light');
};

const callAddFontRobotoMedium = function () {
  this.addFileToVFS('Roboto-Medium.ttf', fontRobotoMedium);
  this.addFont('Roboto-Medium.ttf', 'Roboto', 'medium');
};

const callAddFontRobotoLight = function () {
  this.addFileToVFS('Roboto-Light.ttf', fontRobotoLight);
  this.addFont('Roboto-Light.ttf', 'Roboto', 'light');
};

const callAddFontRobotoBold = function () {
  this.addFileToVFS('Roboto-Bold.ttf', fontRobotoBold);
  this.addFont('Roboto-Bold.ttf', 'Roboto', 'bold');
};

const callAddFontRobotoRegular = function () {
  this.addFileToVFS('Roboto-Regular.ttf', fontRobotoRegular);
  this.addFont('Roboto-Regular.ttf', 'Roboto', 'regular');
};

jsPDF.API.events.push(['addFonts', callAddFontBold]);
jsPDF.API.events.push(['addFonts', callAddFontRegular]);
jsPDF.API.events.push(['addFonts', callAddFontLight]);
jsPDF.API.events.push(['addFonts', callAddFontRobotoLight]);
jsPDF.API.events.push(['addFonts', callAddFontRobotoMedium]);
jsPDF.API.events.push(['addFonts', callAddFontRobotoBold]);
jsPDF.API.events.push(['addFonts', callAddFontRobotoRegular]);

export {jsPDF};
