const INITIAL_STATE = {
  photoUpload: {
    progress: 0,
    isActive: false,
    url: null,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_PHOTO_UPLOAD_PROGRESS': {
      return {
        ...state,
        photoUpload: {
          progress: action.payload,
          isActive: state.photoUpload.isActive,
          url: state.photoUpload.url,
        },
      };
    }
    case 'SET_PHOTO_UPLOAD_STATUS': {
      return {
        ...state,
        photoUpload: {
          progress: state.photoUpload.progress,
          isActive: action.payload,
          url: state.photoUpload.url,
        },
      };
    }
    case 'SET_PHOTO_UPLOAD': {
      return {...state, photoUpload: {...action.payload}};
    }
    default:
      return state;
  }
};
