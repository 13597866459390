import axios from './authRequest';
import simpleAxios from 'axios';
import {CANCEL_MESSAGE} from '../constants';

let dropdownsSource = simpleAxios.CancelToken.source();

export const CheckIfVehicleExists = id =>
  axios.get(`/api/vehicles/exists/${id}`);
export const CheckIfCollectionExists = id =>
  axios.get(`/api/vehicles/collations/exists/${id}`);
export const GetVehicleByUrl = url => axios.get(`/api${url}`);
export const GetVehicleById = id => axios.get(`/api/vehicles/${id}`);
export const getVehiclesByIds = ids =>
  axios.get(`/api/vehicles/manage?ids=${ids.join(',')}`);
export const GetVehicleMainDataById = id =>
  axios.get(`/api/vehicles/${id}/main-data`);
export const CreateVehicle = vehicle => axios.post('/api/vehicles', vehicle);
export const DeleteVehicleById = id => axios.delete(`/api/vehicles/${id}`);
export const UpdateVehicleById = (id, vehicle) =>
  axios.put(`/api/vehicles/${id}`, vehicle);

export const GetVehicleTypes = () => axios.get('/api/vehicles/types');
export const GetVehicleTypesById = id => axios.get(`/api/vehicles/types/${id}`);

export const GetVehicleByRegistrationNumber = (
  registrationNumber,
  currentVehicleId
) =>
  axios.get(
    `/api/vehicles/search/registration/${registrationNumber}?currentVehicleId=${
      currentVehicleId || ''
    }`
  );
export const GetVehicleByVinCode = vinCode =>
  axios.get(`/api/vehicles/search/vinCode/${vinCode}`);

export const GetMileageByRegistrationNumber = registrationNumber =>
  axios.get(`/api/vehicles/search/mileage/registration/${registrationNumber}`);
export const GetMileageByVinCode = vinCode =>
  axios.get(`/api/vehicles/search/mileage/vinCode/${vinCode}`);

export const FetchGearbox = () => axios.get('/api/vehicles/dropdowns/gearbox');
export const FetchDropdowns = vehicle => {
  dropdownsSource.cancel(CANCEL_MESSAGE);
  dropdownsSource = simpleAxios.CancelToken.source();
  return axios.post('/api/vehicles/dropdowns', vehicle, {
    cancelToken: dropdownsSource.token,
  });
};
export const FetchNamedDropdown = (vehicle, name) =>
  axios.post(`/api/vehicles/dropdowns/${name}`, vehicle);

export const GetConditions = () =>
  axios.get('/api/vehicles/dropdowns/condition-descriptions');

export const FetchEmission = vehicle =>
  axios.post('/api/vehicles/dropdowns/emission-standard', vehicle);

export const UpdateInternalNote = (id, internalNote) =>
  axios.patch(`/api/vehicles/${id}/internal-note`, internalNote);

export const PatchWarehouseAndSku = (ids, warehouseAndSku) =>
  axios.patch(`/api/vehicles/warehouse-and-sku`, {
    ...warehouseAndSku,
    ids: ids,
  });

export const PatchWarehouseAndLocation = updateObject =>
  axios.patch('/api/vehicles/warehouse-and-location', updateObject);

export const GetVehiclesStatus = () =>
  axios.get('/api/vehicles/dropdowns/status');
export const UpdateVehiclesStatus = newStatus =>
  axios.put('/api/vehicles/dropdowns/status', newStatus);

export const GetAllBrandNames = () => axios.get('/api/vehicles/brands');
export const GetVehicleMainDataByPartId = id =>
  axios.get(`/api/vehicles/part/${id}/main-data`);

export const GetPopularCategories = id =>
  axios.get(`/api/vehicles/${id}/parts/popular-categories`);
export const GetVehicleDefaultPartTypes = vehicleId =>
  axios.get(`/api/vehicles/default-categories?vehicleId=${vehicleId}`);

export const getVehicleCsv = (base64, isVehicleAsCollection = false) =>
  axios.get(
    `/api/vehicles/${isVehicleAsCollection ? 'collectionCsv' : 'vehicleCsv'}`,
    {params: {base64: base64}}
  );

export const getVehiclesPdf = base64 =>
  axios.get('/api/vehicles/pdf', {params: {base64: base64}});
export const getAllVehiclesPdf = base64 =>
  axios.get('/api/vehicles/all-pdf', {params: {base64: base64}});
export const getAllCollectionsPdf = base64 =>
  axios.get('/api/collections/all-pdf', {params: {base64: base64}});
export const updateVehicleSpecifics = (vehicleId, mainVehicleData) =>
  axios.patch(`api/vehicles/${vehicleId}/specifics`, mainVehicleData);
export const getVehiclesByProductId = productIds =>
  axios.post('/api/lists/vehicles/for-list/by-product-ids', productIds);
export const getVehicleByProductId = productId => {
  return axios.get(`/api/vehicles/update/product/${productId}`);
};
export const getVehiclesByEncodedUrlParams = params =>
  axios.get(`/api/public/vehicles${params}`);
export const vehicleUpdateByProductIdUrl = '/vehicles/update/product/';
export const getVehicleIdByPartId = partId =>
  axios.get('/api/vehicles', {params: {partId}});
export const publicSearchVehicles = term =>
  axios.get(`/api/public/vehicles/search${term}`);
export const getNextAndPrevVehicle = (id, makeAsCollectionVersion) =>
  axios.get('/api/vehicles/nearest-ids', {
    params: {
      id,
      makeAsCollectionVersion,
    },
  });
export const getTotalSold = id =>
  axios.get(`/api/vehicles/sold-price-sum/${id}`);
