import {homeRoutes, USER_ROLE_ID} from '../../constants';

const INITIAL_STATE = {
  isSignedIn: false,
  message: null,
  isInitialised: false,
  pageUrl: '/home',
  userRoleId: null,
  warehouseId: null,
};
const defaultMessage = 'Bad request. Please refresh the page';
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SIGN_IN': {
      return {
        ...state,
        isSignedIn: true,
        message: null,
        userRoleId: parseInt(action.payload.role),
        pageUrl: homeRoutes[parseInt(action.payload.role)],
        userId: action.payload?.id,
        warehouseId: action.payload?.warehouseId,
      };
    }
    case 'SIGN_IN_FAILED': {
      if (action.payload) {
        return {...state, isSignedIn: false, message: action.payload};
      }
      return {...state, isSignedIn: false, message: defaultMessage};
    }
    case 'INITIALIZE_FAIL':
      return {...state, isSignedIn: false, message: null, isInitialised: true};

    case 'INITIALIZE':
      return {
        ...state,
        isSignedIn: true,
        message: null,
        isInitialised: true,
        userRoleId: parseInt(action.payload?.role || `${USER_ROLE_ID}`),
        userId: action.payload?.id,
        warehouseId: action.payload?.warehouseId,
      };

    case 'SIGN_OUT':
      return {...state, isSignedIn: false, pageUrl: '/'};

    case 'CHANGE_PAGE_URL':
      return {...state, pageUrl: action.payload};

    default:
      return state;
  }
};
