import axios from './authRequest';
import {toBase64Json} from '../helpers/global/functions/convert';

export const GetColors = () => axios.get('/api/colors');

export const GetLocations = () => axios.get('/api/locations');

export const getAllCountries = dateTime =>
  axios.get(`/api/countries/${dateTime}`);

export const getExpandedCountries = () => axios.get('/api/expanded-countries');

export const getUserWarehouseId = () => axios.get('/api/user-warehouse-id');

export const getUserMarketplaceAccounts = () =>
  axios.get('/api/user-marketplaces');

export const getUserOrderMarketplaceAccounts = base64 =>
  axios.get(`/api/user-order-marketplaces?base64=${base64}`);

export const getUserDefaultMarketplaceAccountIds = () =>
  axios.get('/api/user-default-marketplace-account-ids');

export const getRoles = () => axios.get('/api/roles');

export const getUserWarehouseCountry = () =>
  axios.get('/api/user-warehouse-country');

export const getWarehouses = () => axios.get('/api/warehouses');

export const getImageFromUrl = url =>
  axios.get(`/api/image?base64=${toBase64Json(url)}`, {
    responseType: 'arraybuffer',
  });
