import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {createStore, applyMiddleware, compose} from 'redux';
import reduxThunk from 'redux-thunk';
import {createPolyglotMiddleware} from 'redux-polyglot';

import App from './components/app/App';
import reducers from './reducers/';
import interceptor from './components/authComponents/interceptor';
import translations from './translations.json';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const polyglotMiddleware = createPolyglotMiddleware(
  'SET_LANGUAGE',
  action => {
    return action.payload.locale;
  },
  locale => {
    return new Promise(resolve => {
      // perform async here
      resolve(translations[locale]);
    });
  }
);
const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(reduxThunk, polyglotMiddleware))
);
interceptor.interceptor(store);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.querySelector('#root')
);
