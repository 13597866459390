import {combineReducers} from 'redux';
import authReducer from './authReducers/authReducer';
import {reducer as loginFormReducer} from 'redux-form';
import {polyglotReducer} from 'redux-polyglot';
import cartReducer from './cartReducers/cartReducer';
import itemReducer from './productReducers/itemReducer';
import backgroundTasksReducer from './backgroundTasksReducers/backgroundTasksReducer';

export default combineReducers({
  auth: authReducer,
  polyglot: polyglotReducer,
  form: loginFormReducer,
  cart: cartReducer,
  items: itemReducer,
  backgroundTasks: backgroundTasksReducer,
});
