export const USER_MARKETPLACE_ACCOUNT_PARAM = 'marketplaceAccountId';
export const WAREHOUSE_URL_PARAM = 'warehouseIds';
export const QUERY_URL_PARAM = 'query';
export const STATUS_OPTION_URL_PARAM = 'option';
export const QUANTITY_URL_PARAM = 'quantity';
export const PAGE_URL_PARAM = 'page';
export const IDS_URL_PARAM = 'ids';
export const PART_IDS_URL_PARAM = 'partIds';
export const VEHICLE_IDS_URL_PARAM = 'vehicleIds';
export const BIN_IDS_URL_PARAM = 'binIds';
export const LIST_IDS_URL_PARAM = 'listIds';
export const SHIPPING_IDS_URL_PARAM = 'shippingIds';
export const ORDER_IDS_URL_PARAM = 'orderIds';
export const MPN_URL_PARAM = 'mpn';
export const SKU_URL_PARAM = 'sku';
export const ENGINE_URL_PARAM = 'engine';
export const ENGINE_CODE_URL_PARAM = 'engineCode';
export const GEARBOX_URL_PARAM = 'gearbox';
export const RRR_ID_URL_PARAM = 'rrrId';
export const EBAY_ID_URL_PARAM = 'ebayId';
export const ENGINE_CODES_URL_PARAM = 'engineCode';
export const DAVIVA_ORDER_ID_URL_PARAM = 'davivaId';
export const RRR_ORDER_ID_URL_PARAM = 'rrrOrderId';
export const EBAY_ORDER_ID_URL_PARAM = 'ebayOrderId';
export const INVOICE_NUMBER_URL_PARAM = 'invoiceNumber';
export const USERNAME_URL_PARAM = 'usernames';
export const SEARCH_OPTION_URL_PARAM = 'search';
export const GET_FAKE_VEHICLES_URL_PARAM = 'getFakeVehicles';
export const INTERNAL_NOTES_URL_PARAM = 'internalNotes';
export const ORDER_BY_OPTION = 'orderOption';
export const PARCEL_SIZE_OPTION = 'parcelSize';
