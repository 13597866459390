import React, {Suspense, Fragment, lazy} from 'react';
import LoadingScreen from './components/loader/LoadingScreen';
import {Switch} from 'react-router-dom';
import UserAuthGuard from './components/authComponents/UserAuthGuard';
import AdminAuthGuard from './components/authComponents/AdminAuthGuard';
import GuestGuard from './components/authComponents/GuestGuard';
import MainLayout from './layouts/MainLayout';
import Page from './Page';
import PackerAuthGuard from './components/authComponents/PackerAuthGuard';
import PickerAuthGuard from './components/authComponents/PickerAuthGuard';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;
        return (
          <Page
            key={i}
            path={route.path}
            exact={route.exact}
            title={route.title}
            changeTitle={!route.routes}
            render={props => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} {...(route.props || {})} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);
// later on we will need to add which top app bar to show in mainLayout. Next to layout: MainLayout we will add topAppBar: VehiclesAppBar ???
const routes = [
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('./pages/Login')),
  },
  {
    exact: true,
    guard: UserAuthGuard,
    path: '/test',
    component: lazy(() => import('./pages/Test')),
  },
  {
    exact: true,
    guard: AdminAuthGuard,
    path: '/admin',
    layout: MainLayout,
    component: lazy(() => import('./pages/Admin')),
    title: 'Admin',
  },
  {
    exact: true,
    guard: UserAuthGuard,
    path: '/settings',
    layout: MainLayout,
    component: lazy(() => import('./pages/Settings')),
    title: 'Settings',
  },
  {
    exact: true,
    guard: PackerAuthGuard,
    path: ['/packing-orders'],
    layout: MainLayout,
    component: lazy(() => import('./pages/PackingOrders.js')),
    title: 'Packing Orders',
    props: {isPacking: true},
  },
  {
    exact: true,
    guard: PickerAuthGuard,
    path: ['/picking-orders'],
    layout: MainLayout,
    component: lazy(() => import('./pages/PackingOrders.js')),
    title: 'Picking Orders',
    props: {isPacking: false},
  },
  {
    exact: true,
    guard: AdminAuthGuard,
    path: '/ebay-token',
    component: lazy(() => import('./pages/EbayToken')),
    title: 'Ebay Token',
    layout: MainLayout,
  },
  {
    exact: true,
    guard: AdminAuthGuard,
    path: '/unassigned-rrr-categories',
    component: lazy(() => import('./pages/UnassignedRrrCategories')),
    title: 'unassigned rrr categories',
    layout: MainLayout,
  },
  {
    exact: true,
    path: ['/guest/vehicles/show', '/guest/vehicles'],
    component: lazy(() => import('./pages/GuestVehicles')),
    title: 'Vehicle',
  },
  {
    exact: true,
    path: ['/guest/parts/show', '/guest/parts'],
    component: lazy(() => import('./pages/GuestParts')),
    title: 'Parts',
  },
  {
    exact: true,
    path: '/test',
    component: lazy(() => import('./pages/Test')),
  },
  {
    guard: UserAuthGuard,
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/vehicles/add',
        component: lazy(() => import('./pages/AddVehicle')),
        title: 'Add Vehicle',
      },
      {
        exact: true,
        path: ['/home', '/'],
        component: lazy(() => import('./pages/Home')),
        title: 'Home',
      },
      {
        exact: true,
        path: ['/vehicles/update/:id', '/vehicles/update/product/:id'],
        component: lazy(() => import('./pages/AddVehicle')),
        title: 'Update Vehicle',
      },
      {
        exact: true,
        path: '/vehicles',
        component: lazy(() => import('./pages/Vehicles')),
        title: 'Vehicles',
      },
      {
        exact: true,
        path: ['/vehicles/:id(\\d+)', '/vehicles/prerender/:id(\\d+)'],
        component: lazy(() => import('./pages/Vehicle')),
        title: 'Vehicle',
      },
      {
        exact: true,
        path: ['/collections/:id(\\d+)', '/collections/prerender/:id(\\d+)'],
        component: lazy(() => import('./pages/Collection')),
        title: 'Collection',
      },
      {
        exact: true,
        path: '/collections',
        component: lazy(() => import('./pages/Collections')),
        title: 'Collections',
      },
      {
        exact: true,
        path: '/collections/add',
        component: lazy(() => import('./pages/AddCollection')),
        title: 'Add Collection',
      },
      {
        exact: true,
        path: ['/collections/update/:id', '/collections/update/product/:id'],
        component: lazy(() => import('./pages/AddCollection')),
        title: 'Update Collection',
      },
      {
        exact: true,
        path: [
          '/lists/parts/manage',
          '/parts/manage',
          '/parts/manage/by-product-ids',
          '/bins/manage',
          '/vehicles/parts/manage',
        ],
        component: lazy(() => import('./pages/AddPart')),
        title: 'Add Part',
      },
      {
        exact: true,
        path: ['/parts'],
        component: lazy(() => import('./pages/Parts')),
        title: 'Parts',
      },
      {
        exact: true,
        path: ['/parts/:id', '/parts/prerender/:id'],
        component: lazy(() => import('./pages/Part')),
        title: 'Part',
      },
      {
        exact: true,
        path: '/lists',
        component: lazy(() => import('./pages/Lists')),
        title: 'Lists',
      },
      {
        exact: true,
        path: ['/orders', '/orders/:checkoutOrderId'],
        component: lazy(() => import('./pages/Orders')),
        title: 'Orders',
      },
      {
        exact: true,
        path: '/deleted',
        component: lazy(() => import('./pages/Deleted')),
        title: 'Deleted',
      },
      {
        exact: true,
        path: '/bins',
        component: lazy(() => import('./pages/Bins')),
        title: 'Bins',
      },
      {
        exact: true,
        path: '/chat',
        component: lazy(() => import('./pages/Chat')),
        title: 'Chat',
      },
      {
        exact: true,
        path: '/patch-notes',
        component: lazy(() => import('./pages/PatchNotes')),
        title: 'Patch notes',
      },
      {
        exact: true,
        component: lazy(() => import('./pages/NotFound')),
        title: 'Not Found',
      },
    ],
  },
];
export default routes;
