export const removeEmptyArraysUndefined = object => {
  if (object instanceof Object) {
    const entries = Object.entries(object);
    for (const [key, value] of entries) {
      if (value instanceof Array && value.length === 0) {
        delete object[key];
      }
      if (value === undefined) {
        delete object[key];
      }
    }
  }
  return object;
};
