import React, {useMemo, useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
  Tooltip,
  IconButton,
  ClickAwayListener,
  Box,
  Typography,
  Paper,
  LinearProgress,
} from '@material-ui/core';
import {MdPendingActions} from 'react-icons/md';
import {NavLink as RouterNavLink} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  iconButton: {
    '&.MuiIconButton-root.Mui-disabled': {
      opacity: '0.1',
    },
    color: 'white !important',
    '& .MuiIconButton-label': {
      fontSize: '21px',
    },
  },
  tooltip: {
    backgroundColor: 'white',
    maxWidth: 'initial',
  },
  paper: {
    padding: 8,
    margin: 8,
    '&:hover': {
      background: theme.palette.action.hover,
    },
    transition: 'background 0.2s',
  },
  linearProgressBox: {
    width: '20vw',
  },
  url: {
    maxWidth: '20vw',
    overflow: 'hidden',
    wordWrap: 'initial',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

const TooltipContent = ({tasks, p, classes, completedTasks}) => {
  // make different colors

  return (
    <Box>
      {tasks.map(([key, value], index) => {
        return (
          <Paper
            key={index}
            style={{display: 'block'}}
            className={classes.paper}
          >
            <Typography variant="subtitle1">{p.tc(key)}</Typography>
            <Box className={classes.linearProgressBox}>
              <LinearProgress variant="determinate" value={value.progress} />
            </Box>
          </Paper>
        );
      })}
      {completedTasks.map(([key, value], index) => {
        return (
          <Paper
            key={index}
            component={RouterNavLink}
            to={value?.url || '#'}
            style={{
              textDecoration: 'none',
              textTransform: 'none',
              display: 'block',
            }}
            className={classes.paper}
          >
            <Typography variant="subtitle1">{p.tc(key)}</Typography>
            <Typography className={classes.url} variant="subtitle2">
              {value.url}
            </Typography>
          </Paper>
        );
      })}
    </Box>
  );
};

const BackgroundTasks = ({tasks, p}) => {
  const classes = useStyles();
  const [completedTasks, setCompletedTasks] = useState([]);
  const [finishedActiveTasks, setFinishedActiveTasks] = useState([]);
  const [activeTasks, setActiveTasks] = useState([]);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [startNotifyContent, setStartNotifyContent] = useState([]);
  const [finishNotifyContent, setFinishNotifyContent] = useState([]);

  useEffect(() => {
    if (!tasks) {
      return;
    }
    const completed = Object.entries(tasks).filter(
      ([, value]) => value.isActive && value.progress === 100
    );
    const active = Object.entries(tasks).filter(([, value]) => value.isActive);
    setCompletedTasks(prev => [...prev, ...completed]);
    setFinishedActiveTasks(completed);
    setActiveTasks(active);
  }, [tasks]);

  useEffect(() => {
    const started = activeTasks.filter(([, value]) => value.progress === 0);
    started.length > 0 &&
      setStartNotifyContent(prev => [
        ...prev,
        ...started.map(([key]) => p.tc(key)),
      ]);
  }, [activeTasks, p]);

  useEffect(() => {
    if (startNotifyContent.length === 0) {
      return;
    }
    const time = setTimeout(() => {
      setStartNotifyContent([]);
    }, 5000);
    return () => clearTimeout(time);
  }, [startNotifyContent]);

  useEffect(() => {
    if (tooltipOpen) {
      setStartNotifyContent([]);
      setFinishNotifyContent([]);
    }
  }, [tooltipOpen]);

  useEffect(() => {
    finishedActiveTasks.length > 0 &&
      setFinishNotifyContent(prev => [
        ...prev,
        ...finishedActiveTasks.map(([key]) => p.tc(key)),
      ]);
  }, [finishedActiveTasks, p]);

  useEffect(() => {
    if (finishNotifyContent.length === 0) {
      return;
    }
    const time = setTimeout(() => {
      setFinishNotifyContent([]);
    }, 5000);
    return () => clearTimeout(time);
  }, [finishNotifyContent]);

  const disabled = useMemo(() => {
    return completedTasks.length === 0 && activeTasks.length === 0;
  }, [completedTasks, activeTasks]);

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose}
        open={tooltipOpen}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={
          <TooltipContent
            p={p}
            classes={classes}
            tasks={activeTasks}
            completedTasks={completedTasks}
          />
        }
        classes={{tooltip: classes.tooltip}}
        interactive
      >
        <IconButton
          disabled={disabled}
          className={classes.iconButton}
          onClick={handleTooltipOpen}
        >
          <MdPendingActions />

          <Tooltip
            open={
              startNotifyContent.length > 0 || finishNotifyContent.length > 0
            }
            classes={{tooltip: classes.tooltip}}
            title={
              <div>
                {startNotifyContent.map((c, index) => (
                  <Typography variant="subtitle1" key={index}>
                    {c}: {p.tc('started')}
                  </Typography>
                ))}
                {finishNotifyContent.map((c, index) => (
                  <Typography variant="subtitle1" key={index}>
                    {c}: {p.tc('finished')}
                  </Typography>
                ))}
              </div>
            }
          >
            <div />
          </Tooltip>
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  );
};
const mapStateToProps = state => {
  return {
    tasks: state.backgroundTasks,
  };
};

BackgroundTasks.propTypes = {
  p: PropTypes.object,
  tasks: PropTypes.object,
};

TooltipContent.propTypes = {
  tasks: PropTypes.array,
  p: PropTypes.object,
  classes: PropTypes.object,
  completedTasks: PropTypes.array,
};
export default connect(mapStateToProps)(BackgroundTasks);
