import React from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {IconButton, Grid, Typography} from '@material-ui/core';
import {ReactComponent as Close} from '../../images/actions/delete.svg';

const useStyles = makeStyles(theme => ({
  iconHeight: {
    height: '24px',
    fill: theme.palette.primary.main,
  },
  closeIcon: {
    '& line': {
      stroke: theme.palette.primary.main,
    },
  },
  dots: {
    marginTop: 10,
    textOverflow: 'ellipsis !important',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  rightAlign: {
    justifyContent: 'flex-end',
    display: 'flex',
  },
  info: {
    fontSize: 12,
  },
}));

const DialogTitle = ({
  handleDialog,
  title,
  subText,
  info,
  style,
  middleComponent,
  noClose = false,
}) => {
  const classes = useStyles();

  return (
    <Grid container justifyContent="space-between" style={style}>
      <Grid item xs={11} style={{display: 'flex', alignItems: 'center'}}>
        <Typography variant="h6" gutterBottom className={classes.dots}>
          {info || title}
        </Typography>
        {subText && (
          <Typography
            variant="subtitle2"
            gutterBottom
            className={classes.dots}
            style={{marginLeft: '10px'}}
          >
            {subText}
          </Typography>
        )}
        {middleComponent && (
          <div
            style={{
              flexGrow: 1,
              paddingLeft: 10,
              paddingRight: 10,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {middleComponent}
          </div>
        )}
      </Grid>
      <Grid item xs={1} className={classes.rightAlign}>
        {!noClose && (
          <IconButton className={classes.closeMargins} onClick={handleDialog}>
            <Close className={clsx(classes.iconHeight, classes.closeIcon)} />
          </IconButton>
        )}
      </Grid>
      {info && (
        <Grid item xs={10} style={{display: 'flex', alignItems: 'center'}}>
          <Typography
            variant="h6"
            gutterBottom
            className={`${classes.dots} ${classes.info}`}
          >
            {title}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

DialogTitle.propTypes = {
  handleDialog: PropTypes.func,
  title: PropTypes.string,
  subText: PropTypes.string,
  info: PropTypes.string,
  style: PropTypes.object,
  noClose: PropTypes.bool,
  middleComponent: PropTypes.element,
};

export default DialogTitle;
