import axios from 'axios';

export const authenticate = axios.create({
  baseURL: '/auth/authenticate',
});
export const refreshCsrfToken = () =>
  axios.create({
    baseURL: '/auth/refresh-csrf-token',
  });
export const checkAuth = axios.create({
  baseURL: '/auth/check-auth-token',
});
export const revokeToken = token => axios.post('/auth/revoke-token', token);
